.SingleDatePicker {
  width: 100%;
  height: 38px;
  border-radius: 4px;
}

.SingleDatePickerInput {
  width: 100%;
  height: 40px;
  border-radius: 4px;
}

.DateInput {
  width: 100%;
  height: 38px;
  border-radius: 4px;
}

.CalendarDay__default:hover {
  background: #dfe8ed;
}

.CalendarDay__selected_span {
  background: #82e0aa;
  color: white;
  border: 1px solid #ffcccb;
}

.CalendarDay__selected {
  background: #3b8585;
  color: white;
}

.CalendarDay__selected:hover {
  background: #3b8585;
  color: white;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #3b8585;
}

.DateInput_input {
  height: 38px;
  border-radius: 4px;
  font-size: 16px;
}

::placeholder {
  color: #7d8790 !important;
}

.DateInput_input__focused {
  height: 38px;
  border-bottom: 2px solid #3b8585;
}

::placeholder {
  opacity: 1;
  font-weight: 400;
  font-size: 1rem;
}

.DateInput_input__focused::placeholder {
  opacity: 1;
  font-weight: 400;
  font-size: 1rem;
}

.SingleDatePickerInput__withBorder {
  border: 1px solid #ced2d5;
}
